<template>
  <div
    class="el-input el-input-group el-input-group--prepend m-0 creditorsInfoCurr"
  >
    <div class="el-input-group__prepend">RM</div>
    <div class="el-input__wrapper">
      <input
        ref="inputRef"
        class="el-input__inner text-end disabled"
        type="text"
        autocomplete="off"
        tabindex="0"
        inputmode="numeric"
        id="el-id-6772-48"
        disabled
      />
    </div>
  </div>
</template>

<script>
import { watch } from "vue";
import { parse, useCurrencyInput } from "vue-currency-input";
import { useField } from "vee-validate";

export default {
  name: "CurrencyInput",
  // props: {
  //   name: {
  //     type: String,
  //     required: true,
  //   },
  //   label: {
  //     type: String,
  //   },
  //   modelValue: Number,
  //   options: Object,
  // },
  props:['name','label','modelValue','options'],
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput(
      props.options
    );

    const { value, errorMessage } = useField(props.name);

    watch(
      // () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );

    return { inputRef, formattedValue, errorMessage, value };
  },
};
</script>
<style scoped>
.el-input__inner.disabled {
  background-color: #f5f7fa !important;
  color: #606266 !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}
</style>
