
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  QuestionFilled,
} from "@element-plus/icons-vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputDisabled from "@/components/financial/CurrencyInputDisabled.vue";
import DescriptionModal from "./modal/Description.vue";

export default defineComponent({
  name: "incme-household",
  props: ["theTax", "theTax_deduction_limit", "theTax_deduction_description"],
  components: {
    // Search,
    // QuestionFilled
    Field,
    ElCurrencyInput,
    // ElCurrencyInputDisabled,
    DescriptionModal,
  },
  setup() {
    const { t, te, n } = useI18n();
    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };
    return {
      Search,
      nFormat,
    };
  },
  data() {
    return {
      tax: this.theTax,
      limit: this.theTax_deduction_limit,
      description: this.theTax_deduction_description,
      dialogVisible_1: false,
      dialogVisible_2: false,
      dialogVisible_3: false,
      dialogVisible_4: false,
      dialogVisible_5: false,
    };
  },
  methods: {
    onChangeChildrenBelow18() {
      this.tax.deduction.child_below_18_years_old =
        this.tax.no_child_below_18_years_old *
        this.limit.child_below_18_years_old;
    },
    onChangeChildren18Above() {
      this.tax.deduction.child_above_18_full_time_education =
        this.tax.no_child_above_18_full_time_education *
        this.limit.child_above_18_full_time_education;
    },
    onChangeChildren18AboveFurtherEducation() {
      this.tax.deduction.child_above_18_further_education =
        this.tax.no_child_above_18_further_education *
        this.limit.child_above_18_further_education;
    },
    onChangeChildrenDisabled() {
      this.tax.deduction.disabled_child =
        this.tax.no_disabled_child * this.limit.disabled_child;
    },
    onChangeChildrenDisabledFurtherEducation() {
      this.tax.deduction.disabled_child_to_college =
        this.tax.no_disabled_child_to_college *
        this.limit.disabled_child_to_college;
    },
    onNextStep() {
      this.$emit("next", this.tax);
    },
    onPrevStep() {
      this.$emit("prev", this.tax);
    },
  },
});
