export const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

export const residentialStatusOptions = [
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Others",
    label: "Others",
  },
];

export const educationOptions = [
  {
    value: "None",
    label: "None",
  },
  {
    value: "School (Primary/Secondary)",
    label: "School (Primary/Secondary)",
  },
  {
    value: "Undergraduate (Certificate/Diploma/Degree)",
    label: "Undergraduate (Certificate/Diploma/Degree)",
  },
  {
    value: "Postgraduate/Professional",
    label: "Postgraduate/Professional",
  },
];

export const employmentOptions = [
  {
    value: "Government",
    label: "Government",
  },
  {
    value: "Private",
    label: "Private",
  },
  {
    value: "Retiree",
    label: "Retiree",
  },
  {
    value: "Self Employed",
    label: "Self Employed",
  },
  {
    value: "Unemployed",
    label: "Unemployed",
  },
];

export const maritalStatusOptions = [
  {
    value: "Single",
    label: "Single",
  },
  {
    value: "Married",
    label: "Married",
  },
  {
    value: "Widowed",
    label: "Widowed",
  },
  {
    value: "Divorced",
    label: "Divorced",
  },
];

export const trusteeOptions = [
  {
    value: "AmanahRaya Trustees Berhad",
    label: "AmanahRaya Trustees Berhad",
  },
  { value: "RHB Trustees Berhad", label: "RHB Trustees Berhad" },
  {
    value: "PB Trustee Services Berhad",
    label: "PB Trustee Services Berhad",
  },
  { value: "Maybank Trustees Berhad", label: "Maybank Trustees Berhad" },
  {
    value: "CIMB Commerce Trustee Berhad ",
    label: "CIMB Commerce Trustee Berhad ",
  },
  { value: "CIMB Islamic Trustee Bhd", label: "CIMB Islamic Trustee Bhd" },
  { value: "Rockwills Trustee Bhd", label: "Rockwills Trustee Bhd" },
  { value: "Affin Hwang Trustee Bhd", label: "Affin Hwang Trustee Bhd" },
  { value: "Pacific Trustees Bhd", label: "Pacific Trustees Bhd" },
];
