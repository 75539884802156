
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import {
  defaultProfile,
  defaultParent,
  defaultExecutors,
  defaultTrustees,
} from "../../../utils/ProfileUtils";

import PersonalDeduction from "./PersonalDeduction.vue";
import StatutoryIncome from "./StatutoryIncome.vue";
import ChildRelatedDeductions from "./ChildRelatedDeductions.vue";
import ContributionAndOthersVue from "./ContributionAndOthers.vue";
import LifestyleMedicalAndEquipmentRelated from "./LifestyleMedicalAndEquipmentRelated.vue";
import TaxSummary from "./TaxSummary.vue";

export default defineComponent({
  name: "calculators",
  components: {
    Field,
    // ElCurrencyInput,
    PersonalDeduction,
    ChildRelatedDeductions,
    ContributionAndOthersVue,
    LifestyleMedicalAndEquipmentRelated,
    TaxSummary,
    StatutoryIncome,
  },
  setup() {
    const { n } = useI18n();

    return {
      n,
    };
  },
  data() {
    const customerNRICToSearch = "831023045007";
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const taxPlannings = [] as any;

    const tax = {
      income: 0,
      income_from_employment: 0,
      income_from_rents: 0,
      income_others: 0,
      income_aggregate: 0,
      is_disabled: "No",
      marital_status: "Single",
      spouse: {
        is_disabled: "No",
        is_working: "No",
      },
      is_pay_alimony: "No",

      is_provide_parents: "No",
      is_pay_own_education: "No",

      is_have_child: "No",
      no_child_below_18_years_old: 0,
      no_child_above_18_full_time_education: 0,
      no_child_above_18_further_education: 0,
      is_have_disabled_child: "No",
      no_disabled_child: 0,
      no_disabled_child_to_college: 0,
      deduction: {
        individual_dependent_relatives: 9000,
        disabled_individual: 6000,
        disabled_spouse: 5000,
        spouse_alimony: 4000,
        education_fee: 0,

        domestic_travel_expenses: 0,
        medical_treatment_for_parents: 0,
        basic_supporting_equipment_for_disabled: 0,
        lifestyle_expenses: 0,
        digital_devices: 0,
        sports_equipment: 0,
        breast_feeding_eqipment: 0,
        medical_expenses_for_serious_diseases: 0,

        child_below_18_years_old: 0,
        child_above_18_full_time_education: 0,
        child_above_18_further_education: 0,
        disabled_child: 0,
        disabled_child_to_college: 0,
        kindergartens: 0,

        life_insurance_and_epf: 0,
        life_insurance_premium: 0,
        epf_approved_scheme: 0,
        prs: 0,
        socso: 0,
        education_and_medical_insurance: 0,
        expenses_on_charging_facilities: 0,
        sspn: 0,
        zakat: 0,
        section_110_tax_deduction: 0,
        section_132_and_133_tax_relief: 0,

        donation: 0,
        pcb: 0,
      },
    };

    const tax_deduction_limit = {};
    const tax_deduction_description = {};

    return {
      store: useStore(),
      cstmr_id,
      taxPlannings,
      isDataReady: false,
      customerNRICToSearch,
      active: 0,
      tax,
      tax_deduction_limit,
      tax_deduction_description,
    };
  },
  methods: {
    init() {
      if (!this.cstmr_id) {
        this.showSearchCustomerModal();
      } else {
        this.getTaxPlanning(this.cstmr_id);
      }

      this.store.dispatch(Actions.GET_TAX_LIMITS).then(() => {
        let data = this.store.getters.getTaxLimitsData;

        this.tax_deduction_limit = data.limit;
        this.tax_deduction_description = data.description;

        console.log(
          "tax_deduction_description",
          this.tax_deduction_description
        );
      });
    },
    next() {
      this.active++;
    },
    prev() {
      this.active--;
    },
    handleChange(val) {
      // console.log(val);
    },
    showSearchCustomerModal() {
      new Modal("#searchCustomerModal", {
        backdrop: "static",
        keyboard: false,
      }).show();
    },
    searchCustomerByNRIC() {
      this.showSwalLoading("Fetching Customer Data");
      this.store
        .dispatch(Actions.GET_CUSTOMER_BY_NRIC, this.customerNRICToSearch)
        .then(() => {
          let theProfile = this.store.getters.getCustomerData;
          if (theProfile.id) {
            this.cstmr_id = theProfile.id;
            setTimeout(() => {
              this.redirecting();
            }, 1000);
          } else {
            this.showSwalResult("Customer not found", "error");
          }
        });
    },
    redirecting() {
      this.showSwalLoading("Redirecting");
      setTimeout(() => {
        this.$router
          .push({
            path:
              "/customers/details/" +
              this.cstmr_id +
              "/financial-planning/tax-planning",
          })
          .then(() => {
            window.location.reload();
          });
      }, 1000);
    },
    getTaxPlanning(user_id) {
      this.showSwalLoading("Populating Data");
      this.store.dispatch(Actions.GET_TAX_PLANNINGS, user_id).then(() => {
        this.taxPlannings = this.store.getters.getTaxPlanningsData;
        this.getUserProfile(user_id);
        Swal.close();
      });
    },
    getUserProfile(user_id) {
      this.store.dispatch(Actions.GET_USER_BASIC_PROFILE, user_id).then(() => {
        // this.profile = this.store.getters.getCustomerBasicProfileData;
        // this.isMuslim = this.profile.user_raw_profile?.raw?.is_muslim ?? false;
        setTimeout(() => {
          this.isDataReady = true;
        }, 1000);
      });
    },
    // getFamilyProfile() {
    //   this.store
    //     .dispatch(Actions.GET_USER_FAMILY_PROFILE, this.profile.id)
    //     .then(() => {
    //       const family = this.store.getters.getFamilyProfileData
    //         .user_raw_family;
    //       if (family !== null) {
    //         this.spouses = family.raw.spouses;
    //         this.children = family.raw.children;
    //         this.parents = family.raw.parents;
    //         this.siblings = family.raw.siblings;
    //         this.others = family.raw.others;
    //       }
    //       setTimeout(() => {
    //         // this.getWillProfile();
    //         Swal.close();
    //       }, 500);
    //     });
    // },
    showSwalLoading(message) {
      Swal.fire({
        title: message,
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    showSwalResult(message, icon = "success") {
      Swal.fire({
        icon: icon,
        title: message,
        text: "Do you want to proceed?",
        allowOutsideClick: false,
      }).then((result) => {
        if (message == "Customer not found") {
          this.showSearchCustomerModal();
        }
      });
    },
    saveTaxPlanning(tax_planning) {
      this.showSwalLoading("Saving Tax Planning");

      setTimeout(() => {
        const data = {
          user_id: this.cstmr_id,
          type: "tax",
          raw: tax_planning,
        };
        this.store.dispatch(Actions.STORE_TAX_PLANNING, data).then(() => {
          this.showSwalResult("Tax Planning has been saved");
        });
        Swal.close();
      }, 500);
    },
    // onSaveTaxPlanning(type, data = null) {
    //   this.isMuslim = this.profile.user_raw_profile.raw.is_muslim ?? false;
    //   this.showTaxPlanningSummaryModal = false;

    //   Swal.fire({
    //     title: "Saving " + type + " Information",
    //     text: "Please wait...",
    //     allowOutsideClick: false,
    //     didOpen: () => {
    //       Swal.showLoading();
    //     },
    //   });

    //   setTimeout(() => {
    //     switch (type) {
    //       case "Personal":
    //         this.saveProfile(type);
    //         break;
    //     }
    //   }, 1500);
    // },
    // saveProfile(type, showResult = true) {
    //   console.log("saveProfile", type);
    //   this.store.dispatch(Actions.UPDATE_PROFILE, this.profile).then(() => {
    //     if (type == "Spouses" && showResult) {
    //       this.saveRaw(type, "family");
    //     } else {
    //       if (showResult) {
    //         Swal.fire({
    //           icon: "success",
    //           title: "Personal Information has been saved",
    //           allowOutsideClick: false,
    //         });
    //       }
    //       this.showTaxPlanningSummaryModal = true;
    //     }
    //   });
    // },
    // saveRaw(saved, type, showResult = true) {
    //   console.log("saveProfile", type);

    //   let raw = {};

    //   if (type == "family") {
    //     raw = {
    //       spouses: this.spouses,
    //       children: this.children,
    //       parents: this.parents,
    //       siblings: this.siblings,
    //       others: this.others,
    //     };
    //   }
    //   if (type == "will") {
    //     raw = {
    //       executors: this.executors,
    //       trustees: this.trustees,
    //       distribution: this.distribution,
    //       special_request: this.special_request,
    //       personal_belongings: this.personal_belongings,
    //       residuary_estate: this.residuary_estate,
    //     };
    //   }

    //   let data = {
    //     type: type,
    //     user_id: this.profile.id,
    //     raw: raw,
    //   };

    //   this.store.dispatch(Actions.STORE_RAW, data).then(() => {
    //     if (showResult) {
    //       this.showSwalResult("Your " + saved + " Information has been saved");
    //       this.showTaxPlanningSummaryModal = true;
    //     }
    //   });
    // },
    // submitTaxPlanning(action) {
    //   if (action == "preview") {
    //     this.showSwalLoading("Summarizing Tax Planning");

    //     setTimeout(() => {
    //       Swal.close();
    //       new Modal("#TaxPlanningSummaryModal", {
    //         backdrop: "static",
    //         keyboard: false,
    //       }).show();
    //     }, 1500);
    //   } else {
    //     Swal.fire({
    //       title: "Are you sure?",
    //       text: "You won't be able to revert this!",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonText: "Yes, submit it!",
    //       cancelButtonText: "No, cancel!",
    //       customClass: {
    //         confirmButton: "btn btn-primary btn-active-primary",
    //         cancelButton: "btn btn-danger btn-active-primary ms-2",
    //       },
    //     }).then((result) => {
    //       this.showSwalLoading("Submitting Tax Planning");

    //       setTimeout(() => {
    //         Swal.close();

    //         const data = {
    //           user_id: this.profile.id,
    //           type: "will",
    //           raw: {
    //             profile: this.profile,
    //             spouses: this.spouses,
    //             children: this.children,
    //             parents: this.parents,
    //             siblings: this.siblings,
    //             others: this.others,
    //             executors: this.executors,
    //             trustees: this.trustees,
    //             distribution: this.distribution,
    //             special_request: this.special_request,
    //             personal_belongings: this.personal_belongings,
    //             residuary_estate: this.residuary_estate,
    //           },
    //         };

    //         this.store.dispatch(Actions.STORE_WILL_PLANNING, data).then(() => {
    //           this.showSwalResult("Tax Planning has been submitted");
    //         });
    //       }, 1500);
    //     });
    //   }
    // },
    getRouteUrl(route) {
      return this.$router.resolve(route).href;
    },
  },
  async mounted() {
    this.init();
  },
});
