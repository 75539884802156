
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  QuestionFilled,
} from "@element-plus/icons-vue";

export default defineComponent({
  name: "incme-household",
  props: ["theTax", "theTax_deduction_limit"],
  components: {
    // Search,
    // QuestionFilled
    // Field,
    // ElCurrencyInput,
    // ElCurrencyInputDisabled,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    const tax_deduction = {
      total: 0,
      individual: 0,
      life_style: 0,
      children: 0,
      others: 0,
    };

    const taxable_income = 0;
    const tax_amount = 0;
    const taxBrackets = {
      next_1: { amount: 5000, rate: 0, tax: 0 },
      next_2: { amount: 15000, rate: 1, tax: 0 },
      next_3: { amount: 15000, rate: 3, tax: 0 },
      next_4: { amount: 15000, rate: 6, tax: 0 },
      next_5: { amount: 20000, rate: 11, tax: 0 },
      next_6: { amount: 30000, rate: 19, tax: 0 },
      next_7: { amount: 300000, rate: 25, tax: 0 },
      next_8: { amount: 200000, rate: 26, tax: 0 },
      next_9: { amount: 1400000, rate: 28, tax: 0 },
      next_0: { amount: 2000000, rate: 26, tax: 0 },
    };
    const zakat = 0;
    const rebate = 0;
    const zakat_rebate = 0;
    const tax_payable = 0;
    const tax_charged = 0;
    const tax_relief = 0;

    return {
      tax: this.theTax,
      limit: this.theTax_deduction_limit,
      tax_deduction,
      taxable_income,
      tax_amount,
      taxBrackets,
      zakat,
      rebate,
      zakat_rebate,
      tax_relief,
      tax_payable,
      tax_charged,
      showSummary: false,
    };
  },
  methods: {
    handleChange(value: any) {
      console.log(value);
    },
    onNextStep() {
      this.$emit("next", this.tax);
    },
    onPrevStep() {
      this.$emit("prev", this.tax);
    },
    onCalculateTax() {
      Swal.fire({
        title: "Calculating Tax",
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      this.tax_deduction.total = 0;
      this.tax_deduction.individual = 0;
      this.tax_deduction.life_style = 0;
      this.tax_deduction.children = 0;
      this.tax_deduction.others = 0;

      if (this.tax.is_disabled == "Yes") {
        this.tax_deduction.individual =
          this.tax_deduction.individual +
          this.tax.deduction.disabled_individual;
      }

      if (this.tax.marital_status == "Married") {
        if (this.tax.spouse.is_disabled == "Yes") {
          this.tax_deduction.individual =
            this.tax_deduction.individual + this.tax.deduction.disabled_spouse;
        }

        if (this.tax.spouse.is_working == "No") {
          this.tax_deduction.individual =
            this.tax_deduction.individual + this.tax.deduction.spouse_alimony;
        }
      }

      if (this.tax.marital_status == "Divorced") {
        if (this.tax.is_pay_alimony == "Yes") {
          this.tax_deduction.individual =
            this.tax_deduction.individual + this.tax.deduction.spouse_alimony;
        }
      }

      this.tax_deduction.individual =
        this.tax_deduction.individual +
        this.tax.deduction.individual_dependent_relatives +
        this.tax.deduction.education_fee;

      this.tax_deduction.life_style =
        this.tax.deduction.domestic_travel_expenses +
        this.tax.deduction.medical_treatment_for_parents +
        this.tax.deduction.basic_supporting_equipment_for_disabled +
        this.tax.deduction.lifestyle_expenses +
        this.tax.deduction.digital_devices +
        this.tax.deduction.sports_equipment +
        this.tax.deduction.breast_feeding_eqipment +
        this.tax.deduction.medical_expenses_for_serious_diseases;

      this.tax_deduction.children =
        this.tax.deduction.child_below_18_years_old +
        this.tax.deduction.child_above_18_full_time_education +
        this.tax.deduction.child_above_18_further_education +
        this.tax.deduction.disabled_child +
        this.tax.deduction.disabled_child_to_college +
        this.tax.deduction.kindergartens;

      this.tax_deduction.others =
        this.tax.deduction.life_insurance_and_epf +
        this.tax.deduction.life_insurance_premium +
        this.tax.deduction.epf_approved_scheme +
        this.tax.deduction.prs +
        this.tax.deduction.socso +
        this.tax.deduction.education_and_medical_insurance +
        this.tax.deduction.expenses_on_charging_facilities +
        this.tax.deduction.sspn;

      this.tax_deduction.total =
        this.tax_deduction.individual +
        this.tax_deduction.life_style +
        this.tax_deduction.children +
        this.tax_deduction.others;

      this.taxable_income = this.tax.income - this.tax_deduction.total;
      this.tax_amount = this.calculateTaxOnIncome(this.taxable_income);

      if (this.taxable_income < 35000) {
        this.rebate = 400;
      }

      this.zakat_rebate = this.tax.deduction.zakat + this.rebate;
      this.tax_relief =
        this.tax.deduction.section_110_tax_deduction +
        this.tax.deduction.section_132_and_133_tax_relief;

      this.tax_charged = this.tax_amount - this.zakat_rebate - this.tax_relief;
      this.tax_payable = this.tax_charged - this.tax.deduction.pcb;

      setTimeout(() => {
        Swal.close();
        this.showSummary = true;
      }, 1000);
    },
    calculateTaxOnIncome(income) {
      let totalTax = 0;
      let remainingIncome = income;

      for (const key in this.taxBrackets) {
        if (Object.prototype.hasOwnProperty.call(this.taxBrackets, key)) {
          const { amount, rate } = this.taxBrackets[key];

          // The income that will be taxed at the current rate
          let taxableIncome = Math.min(amount, remainingIncome);

          // Calculate tax for this bracket and add to total
          let tax = (taxableIncome * rate) / 100;
          totalTax += tax;

          // Reduce the remaining income
          remainingIncome -= taxableIncome;

          // Update tax value in the bracket
          this.taxBrackets[key].tax = tax;

          // Break the loop if there's no remaining income
          if (remainingIncome <= 0) break;
        }
      }

      return totalTax;
    },
    onSaveTaxPlanning() {
      const taxPlanning = {
        tax: this.tax,
        tax_deduction: this.tax_deduction,
        taxable_income: this.taxable_income,
        tax_amount: this.tax_amount,
        taxBrackets: this.taxBrackets,
        zakat: this.zakat,
        rebate: this.rebate,
        tax_payable: this.tax_payable,
      };
      this.$emit("saveTaxPlanning", taxPlanning);
    },
  },
});
