
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";

export default defineComponent({
  name: "incme-household",
  props: ["theTax", "theTax_deduction_limit"],
  components: {
    ElCurrencyInput,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    return {
      tax: this.theTax,
      limit: this.theTax_deduction_limit,
      isShowIncomeAggregate: true,
    };
  },
  methods: {
    onNextStep() {
      this.$emit("next", this.tax);
    },
    calculateAggregateIncome() {
      this.tax.income_aggregate =
        this.tax.income_from_employment +
        this.tax.income_from_rents +
        this.tax.income_others;

      this.tax.income = this.tax.income_aggregate - this.tax.donation;
    },
  },
  watch: {
    "tax.income_from_employment": function (newValue, oldValue) {
      this.calculateAggregateIncome();
    },
    "tax.income_from_rents": function (newValue, oldValue) {
      this.calculateAggregateIncome();
    },
    "tax.income_others": function (newValue, oldValue) {
      this.calculateAggregateIncome();
    },
    "tax.donation": function (newValue, oldValue) {
      this.calculateAggregateIncome();
    },
  },
});
