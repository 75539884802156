import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog modal-dialog-centered modal-lg" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header pb-0 border-0 justify-content-start",
  style: {"background-color":"#7080d9"}
}
const _hoisted_5 = { class: "text-royal mb-3 text-white" }
const _hoisted_6 = { class: "modal-body scroll-y" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-12" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12 text-end" }
const _hoisted_12 = {
  type: "button",
  class: "btn btn-sm btn-danger btn-active-primary",
  "data-bs-dismiss": "modal",
  ref: "cancelAddSpouseButton"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    ref: _ctx.modal,
    id: _ctx.modal,
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", { innerHTML: _ctx.description }, null, 8, _hoisted_9)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", _hoisted_12, " Close ", 512)
            ])
          ])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}