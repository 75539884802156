
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  QuestionFilled,
} from "@element-plus/icons-vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import DescriptionModal from "./modal/Description.vue";

export default defineComponent({
  name: "incme-household",
  props: ["theTax", "theTax_deduction_limit", "theTax_deduction_description"],
  components: {
    ElCurrencyInput,
    DescriptionModal,
  },
  setup() {
    return {
      Search,
    };
  },
  data() {
    return {
      tax: this.theTax,
      limit: this.theTax_deduction_limit,
      description: this.theTax_deduction_description,
    };
  },
  methods: {
    handleChange(value: any) {
      console.log(value);
    },
    onNextStep() {
      this.$emit("next", this.tax);
    },
    onPrevStep() {
      this.$emit("prev", this.tax);
    },
  },
});
