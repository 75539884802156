
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  QuestionFilled,
} from "@element-plus/icons-vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputDisabled from "@/components/financial/CurrencyInputDisabled.vue";
import {
  employmentOptions,
  maritalStatusOptions,
} from "../../../../data/FASLookups";

export default defineComponent({
  name: "incme-household",
  props: ["theTax", "theTax_deduction_limit"],
  components: {
    // Search,
    // QuestionFilled
    Field,
    ElCurrencyInput,
    ElCurrencyInputDisabled,
  },
  setup() {
    return {
      Search,
    };
  },
  data() {
    return {
      maritalStatuses: maritalStatusOptions,
      tax: this.theTax,
      limit: this.theTax_deduction_limit,
    };
  },
  methods: {
    onChangeChildrenBelow18() {
      this.tax.deduction.children_below_18 = 0;
      setTimeout(() => {
        this.tax.deduction.children_below_18 =
          this.tax.no_children_below_18 * 2000;
      }, 100);
    },
    onChangeChildren18Above() {
      this.tax.deduction.children_18_and_above = 0;
      this.tax.deduction.children_to_colege = 0;
      setTimeout(() => {
        this.tax.deduction.children_18_and_above =
          (this.tax.no_children_18_and_above - this.tax.no_children_to_colege) *
          2000;

        this.tax.deduction.children_to_colege =
          this.tax.no_children_to_colege * 8000;
      }, 100);
    },
    onChangeChildrenDisabled() {
      this.tax.deduction.disabled_children = 0;
      this.tax.deduction.disabled_children_to_college = 0;
      setTimeout(() => {
        this.tax.deduction.disabled_children =
          (this.tax.no_disabled_child - this.tax.no_disabled_child_to_college) *
          6000;

        this.tax.deduction.disabled_children_to_college =
          this.tax.no_disabled_child_to_college * 14000;
      }, 100);
    },
    onChangeProvideParents() {
      this.tax.deduction.parents = 0;
      setTimeout(() => {
        if (this.tax.is_provide_parents == "No") {
          this.tax.deduction.parents = 0;
        }

        if (this.tax.is_provide_parents == "Yes, One Parent") {
          this.tax.deduction.parents = 8000 * 1;
        }

        if (this.tax.is_provide_parents == "Yes, Both Parents") {
          this.tax.deduction.parents = 8000 * 2;
        }
      });
    },
    onPrevStep() {
      this.$emit("prev", this.tax);
    },
    onNextStep() {
      this.$emit("next", this.tax);
    },
  },
  async mounted() {
    this.tax.deduction.individual_dependent_relatives = this.limit.individual_dependent_relatives;
  },
});
